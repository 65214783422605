.iFrameHeader {
  padding: var(--betterplace-spacing-200) var(--betterplace-spacing-400);
}

.iFrameHeaderInnerContainer {
  margin-inline: auto;
}

.headline {
  color: white;
  text-align: center;
  padding: 12px 0;
  display: flex;
  justify-content: space-between;

  p {
    margin: 0;
  }

  :global(.line1) {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.3;

    &:only-child {
      line-height: 2.3;
    }
  }

  :global(.line2) {
    font-size: 16px;
  }
}

.backButton {
  display: flex;
  align-items: center;
}

.headingContainer {
  flex-basis: 40%;
  flex-grow: 1;
}

.column {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
}
