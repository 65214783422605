.container {
  padding-block: var(--betterplace-sizing-400);
  min-inline-size: min-content;
}

.logo {
  height: var(--betterplace-sizing-500);
  width: min-content;
}

.main {
  max-inline-size: var(--betterplace-measure-body-text);
  margin-bottom: var(--betterplace-spacing-450);
}
