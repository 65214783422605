.container {
  background: var(--betterplace-manage-color-bg-primary);
  padding-block: var(--betterplace-sizing-400);
  min-inline-size: min-content;
}

.flexbox {
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: var(--betterplace-spacing-300);
  flex-wrap: wrap;
}

.logo {
  height: var(--betterplace-sizing-500);
  width: min-content;
}

.link:focus-visible {
  outline: var(--betterplace-border-focused);
  outline-color: var(--betterplace-color-fg-light-primary);
}
