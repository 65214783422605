.paymentMethodSelection {
  row-gap: 0;
  max-width: var(--betterplace-sizing-fix-content-narrow);
}

@container (max-width: env(--betterplace-measure-body-text)) {
  .paymentMethodSelection {
    display: block;
  }
}

.blockWrapper {
  padding-block: var(--betterplace-spacing-300);
}

.headingMargin {
  margin-block-end: var(--betterplace-spacing-200);
}

.donationAmountInput {
  width: 8.75rem;
}

.iFrameInnerContainer {
  padding: var(--betterplace-spacing-400);
  margin-inline: auto;
}

.continueContainer {
  margin-block: var(--betterplace-spacing-400) var(--betterplace-spacing-250);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sslLogo {
  height: var(--betterplace-sizing-700);
}
