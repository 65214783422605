.taxDeductibilityNotice {
  font-weight: bold;
  text-align: center;
  border-block: var(--betterplace-border-light);
}

@media only screen and (width >= 451px) {
  .taxDeductibilityNotice {
    margin-block-start: var(--betterplace-spacing-450);
    padding-block: var(--betterplace-spacing-450);
  }
}

@media only screen and (width <= 450px) {
  .taxDeductibilityNotice {
    margin-block-start: var(--betterplace-spacing-200);
    padding-block: var(--betterplace-spacing-200);
  }
}
