.amountInput {
  width: var(--betterplace-sizing-900);
}

.modal {
  padding-block-end: var(--betterplace-spacing-300);
  margin-block-start: var(--betterplace-spacing-400);
}

.modalInner {
  gap: var(--betterplace-spacing-200);
}

.introduction {
  font: var(--betterplace-typography-functional-text-md-default);
  color: var(--betterplace-color-fg-content-primary);
}

/* Temporary override until new input+button component is created */
.codonateButton {
  align-self: flex-end;
  height: 3.35rem;
}

.organisation {
  display: flex;

  border-top: var(--betterplace-border-light);
  border-bottom: var(--betterplace-border-light);
  padding-top: var(--betterplace-spacing-200);
  padding-bottom: var(--betterplace-spacing-200);

  img {
    margin-right: var(--betterplace-spacing-300);
  }
}

.organisationTeaser {
  align-self: center;
}

.codonationModalContent {
  padding-block: var(--betterplace-spacing-200);
}

.codonationSelectionContainer {
  padding-block: var(--betterplace-spacing-200);
  text-align: left;
}

.codonationInputWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: var(--betterplace-spacing-200);
}

.predefinedImageWrapper {
  position: relative;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 75px;

  @mixin mobile {
    height: 60px;
  }
}

.predefinedImage {
  object-fit: cover;
}

.openCodonationModalButton {
  text-align: left;
  font: var(--betterplace-typography-functional-text-md-important);
  white-space: normal;
}

.predefinedAmountBox {
  border: var(--betterplace-border-light);
  border-radius: var(--betterplace-border-radius-200);
  padding-block: var(--betterplace-spacing-300);
  padding-inline: var(--betterplace-spacing-50);
  font: var(--betterplace-typography-functional-text-lg-important);
  color: var(--betterplace-color-fg-content-primary);

  cursor: pointer;
  text-align: center;
  flex: 1 1;

  &:hover {
    box-shadow: var(--betterplace-shadow-floating-medium);
  }

  &:focus-visible {
    outline: var(--betterplace-border-focused);
    outline-offset: var(--betterplace-spacing-50);
  }
}

.amountBoxesContainer {
  container-type: 'inline-size';
  justify-content: 'space-between';
}

@mixin mobile {
  .amountBoxesContainer {
    gap: var(--betterplace-spacing-100) !important;
  }

  .predefinedAmountBox {
    padding-inline: var(--betterplace-spacing-50);
  }
}

.predefinedAmountContainer {
  container-type: inline-size;
}

/* Until we have a different design we need this hack to fit the content on the screen with smaller viewports as well */
@container (max-width: 96px) {
  .fakeButton {
    font: var(--betterplace-typography-label-sm) !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
