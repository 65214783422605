.scaledWidgetContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0.25rem 0.25rem 0 0;

  & > * {
    transform: scale(0.6);
    transform-origin: center;
  }
}

.fullSizeWidgetContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0.25rem 0.25rem 0 0;
  width: 100%;

  & > * {
    max-width: 50rem;
  }
}
