.modalTrigger {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-block-start: 1rem;
  min-block-size: 3rem;

  &:focus-visible {
    outline: var(--betterplace-border-focused);
    outline-offset: var(--betterplace-spacing-50);
    border-radius: var(--betterplace-border-radius-200);
  }

  @mixin desktop {
    justify-content: flex-start;
  }
}

.codonationFormWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.illustration {
  margin-bottom: 1.5rem;
  width: 100%;
  position: relative;
  padding-top: 52%;
}

.image {
  position: absolute;
  top: 0;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.visibleImage {
  opacity: 1;
}

.fakeInput {
  block-size: 100%;
  inline-size: 100%;
  margin-inline-end: var(--betterplace-spacing-200);
  padding: var(--betterplace-spacing-250) var(--betterplace-spacing-300);
  background: var(--betterplace-color-bg-light);
  border: var(--betterplace-border-default);
  border-radius: var(--betterplace-border-radius-200);
  color: var(--betterplace-color-fg-content-primary);
  font: var(--betterplace-typography-functional-text-lg-default);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 var(--betterplace-sizing-900);
}

.slider {
  margin-block-end: var(--betterplace-spacing-250);
  inline-size: 100%;
}

.inputAndSum {
  inline-size: 100%;
  margin-block-end: var(--betterplace-spacing-250);
  display: flex;
  align-items: center;

  @mixin desktop {
    justify-content: center;
  }
}

.sum {
  font: var(--betterplace-typography-label-md);

  @mixin desktop {
    flex: 1 1;
  }
}

.inputFlexContainer {
  @mixin desktop {
    display: flex;
    justify-content: flex-end;
    flex: 1 1;
  }
}

.inputWrapper {
  inline-size: var(--betterplace-sizing-900);
}

.storeAndCloseButton {
  margin-block-start: var(--betterplace-spacing-250);
}

.closeCodonationModalButton {
  margin-block-start: var(--betterplace-spacing-300);
}

.rangeInput {
  appearance: none;
  height: var(--betterplace-spacing-400);
  width: 100%;
  margin: var(--betterplace-spacing-none);
  padding: var(--betterplace-spacing-none);
  border: none;

  &:focus {
    outline: none;
  }
}
