.scrollableFeedContainer {
  padding-block: var(--betterplace-spacing-200);
  padding-inline: var(--betterplace-spacing-200);
  background-color: #444140;
  background-repeat: repeat;
  display: flex;
  flex-direction: column;
  block-size: 100%;
}

.scrollContainer {
  block-size: initial;
  flex: 1;
}

.newDonationsContainer {
  text-align: center;
  flex: 0;
  justify-content: center;
  padding-inline: unset;
  display: flex;
  max-inline-size: var(--betterplace-sizing-fix-content-narrow);

  @mixin mobile {
    inline-size: 100%;
  }
}

.newDonationsButton {
  flex: 1;
  background-color: transparent;
  border: none;
  border-bottom: var(--betterplace-border-width-important) solid var(--betterplace-color-border-on-color);
  padding-block-end: var(--betterplace-spacing-200);
  margin-block: var(--betterplace-spacing-100) var(--betterplace-spacing-200);
  cursor: pointer;
}

.newDonationsContent {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.iconContainer {
  background-color: var(--betterplace-color-accent-fun-e-secondary);
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  inline-size: 1rem;
  block-size: 1rem;
}

.newDonationsText {
  margin-inline-start: var(--betterplace-spacing-100);
  color: var(--betterplace-color-fg-light-primary);
  font: var(--betterplace-typography-label-xs-secondary);
}
