.centeredContainer {
  text-align: center;
  container-type: inline-size;
}

.imageContainer {
  position: relative;
  inline-size: 100%;
  aspect-ratio: 319/244;
  margin-inline: auto;
  max-inline-size: var(--betterplace-measure-card-max);
}

@container (max-width: env(--betterplace-measure-body-text-lg)) {
  .button {
    inline-size: 100%;
  }
}
