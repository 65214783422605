.rightArrow {
  margin-inline: 0.25rem -0.75rem;
}

.leftArrow {
  margin-inline: -0.75rem 0.25rem;
}

/* This is a one-off override for the slider. */
.leftArrow:hover,
.rightArrow:hover {
  background-color: var(--betterplace-color-bg-primary);
}
