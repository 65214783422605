.blockWrapper {
  padding-block: var(--betterplace-spacing-300);
}

.headingMargin {
  margin-block-end: var(--betterplace-spacing-200);
}

.donationAmountInput {
  width: var(--betterplace-sizing-900);
}

.donationFormContent {
  padding: 2.5rem 1.875rem 3.125rem;
}

.donationFormNotices {
  margin-inline: -10px;
}

.formFieldsContainer {
  display: flex;
  flex-direction: column;
}

@mixin mobile {
  .donationFormContent {
    padding: 1.875rem 0.625rem 1.25rem;
  }

  .donationFormNotices {
    margin-top: 30px;
  }
}
