.card {
  padding: var(--betterplace-spacing-200);
  display: flex;
  flex-direction: column;
  gap: var(--betterplace-spacing-200);
  max-inline-size: var(--betterplace-sizing-fix-content-narrow);
  margin-inline: auto;

  /* light instead of subtle & Card has pre-defined font */
  background-color: var(--betterplace-color-bg-light);
  border-radius: var(--betterplace-border-radius-300);

  .topRow {
    display: flex;
    justify-content: space-between;

    .amount {
      inline-size: 5rem;
      max-inline-size: 5rem;
      flex-grow: 0;
      color: var(--betterplace-color-fg-content-primary);
      font: var(--betterplace-typography-functional-text-md-important);
    }

    .name {
      justify-self: flex-start;
      text-align: left;
      flex: 1;
      color: var(--betterplace-color-bg-fun-e-secondary);
      font: var(--betterplace-typography-functional-text-md-important);
    }

    .time {
      color: var(--betterplace-color-fg-content-secondary);
      font: var(--betterplace-typography-functional-text-md-default);
    }
  }

  .comment {
    color: var(--betterplace-color-fg-content-primary);
    font: var(--betterplace-typography-functional-text-md-default);

    /* showing maximum two lines of text, then ellipsis - screen reader reads the whole text */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--nr-of-lines);
    line-clamp: var(--nr-of-lines);
    block-size: var(--max-comment-height);
    overflow: hidden;
  }

  @mixin mobile {
    .comment {
      -webkit-line-clamp: var(--nr-of-lines-mobile);
      line-clamp: var(--nr-of-lines-mobile);
      block-size: var(--max-comment-height-mobile);
    }
  }

  &:focus-visible {
    outline: var(--betterplace-border-focused);
    outline-offset: var(--betterplace-spacing-100);
  }

  &.readStatus {
    background-color: var(--betterplace-color-bg-success);

    .topRow {
      .name {
        color: var(--betterplace-color-fg-content-primary);
      }
    }
  }
}
